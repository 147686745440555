.html-content-container p {
  font-size: 15px;
  padding: 4px 0;
  line-height: 1.5;
}
.html-content-container h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  padding: 4px 0;
}
.html-content-container h3 {
  font-size: 18px;
  line-height: 1.3;
  color: #016f94;
  padding: 4px 0;
}
.html-content-container h4 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.3;
  padding: 4px 0;
}