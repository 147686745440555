.input-container [multiple], .input-container [type=date], .input-container [type=datetime-local], .input-container [type=email], .input-container [type=month], .input-container [type=number], .input-container [type=password], .input-container [type=search], .input-container [type=tel], .input-container [type=text], .input-container [type=time], .input-container [type=url], .input-container [type=week], .input-container select, .input-container textarea {
  font-family: "Outfit", sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #dde2e3;
  border-width: 1px;
  border-radius: 0.75rem;
  height: 52px;
  padding: 0.8rem 0.8rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}
.input-container [multiple].has-error, .input-container [type=date].has-error, .input-container [type=datetime-local].has-error, .input-container [type=email].has-error, .input-container [type=month].has-error, .input-container [type=number].has-error, .input-container [type=password].has-error, .input-container [type=search].has-error, .input-container [type=tel].has-error, .input-container [type=text].has-error, .input-container [type=time].has-error, .input-container [type=url].has-error, .input-container [type=week].has-error, .input-container select.has-error, .input-container textarea.has-error {
  border-color: #dc4747;
}
.input-container textarea {
  height: 150px;
}

.search-box-input::-webkit-datetime-edit {
  display: block;
  width: 100%;
}

.search-box-input::-webkit-datetime-edit-fields-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.input-container [multiple]:focus, .input-container [type=date]:focus, .input-container [type=datetime-local]:focus, .input-container [type=email]:focus, .input-container [type=month]:focus, .input-container [type=number]:focus, .input-container [type=password]:focus, .input-container [type=search]:focus, .input-container [type=tel]:focus, .input-container [type=text]:focus, .input-container [type=time]:focus, .input-container [type=url]:focus, .input-container [type=week]:focus, .input-container select:focus, .input-container textarea:focus {
  outline: 2px solid rgba(0, 0, 0, 0);
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #006F95;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #006F95;
}

.input-select .select__menu {
  z-index: 2;
  padding: 2px 0;
}
.input-select .select__menu .select__menu-list {
  padding: 2px 0;
}
.input-select .select__menu .select__menu-list .select-option-item {
  padding: 3px 14px;
  cursor: pointer;
}
.input-select .select__menu .select__menu-list .select-option-item:hover {
  background-color: #f5f7f8;
}

.css-t3ipsp-control,
.css-13cymwt-control {
  background-color: #fff !important;
  border-color: #dde2e3 !important;
  border-width: 1px !important;
  border-radius: 7px !important;
  min-height: 52px !important;
}

.border-red .css-t3ipsp-control,
.border-red .css-13cymwt-control {
  border-color: #dc4747 !important;
}

.select__control {
  height: 52px !important;
}
.select__control .select__input {
  height: auto !important;
}