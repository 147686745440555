@font-face {
  font-family: "parkagent";
  src: url("../../public/fonts/parkagent.eot?eg8f3o");
  src: url("../../public/fonts/parkagent.eot?eg8f3o#iefix") format("embedded-opentype"), url("../../public/fonts/parkagent.ttf?eg8f3o") format("truetype"), url("../../public/fonts/parkagent.woff?eg8f3o") format("woff"), url("../../public/fonts/parkagent.svg?eg8f3o#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icon {
  display: block;
  font-family: "parkagent" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  vertical-align: -0.125em;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert:before {
  content: "\e900";
}

.icon-arrow-down:before {
  content: "\e901";
}

.icon-arrow-left:before {
  content: "\e902";
}

.icon-arrow-right:before {
  content: "\e903";
}

.icon-arrow-up:before {
  content: "\e904";
}

.icon-boat:before {
  content: "\e905";
}

.icon-bus-type:before {
  content: "\e906";
}

.icon-bus:before {
  content: "\e907";
}

.icon-cancel:before {
  content: "\e908";
}

.icon-car-clean-interni:before {
  content: "\e909";
}

.icon-car-clean:before {
  content: "\e90a";
}

.icon-car-fresh:before {
  content: "\e90b";
}

.icon-car-moving:before {
  content: "\e90c";
}

.icon-car-type:before {
  content: "\e90d";
}

.icon-car-valet-andata:before {
  content: "\e90e";
}

.icon-car-valet-ritorno:before {
  content: "\e90f";
}

.icon-car-valet:before {
  content: "\e910";
}

.icon-car:before {
  content: "\e911";
}

.icon-check:before {
  content: "\e912";
}

.icon-city:before {
  content: "\e913";
}

.icon-consegna-chiavi:before {
  content: "\e914";
}

.icon-copy:before {
  content: "\e915";
}

.icon-cross:before {
  content: "\e916";
}

.icon-down:before {
  content: "\e917";
}

.icon-h24:before {
  content: "\e918";
}

.icon-info:before {
  content: "\e919";
}

.icon-large-bus:before {
  content: "\e91a";
}

.icon-large-card:before {
  content: "\e91b";
}

.icon-large-computer:before {
  content: "\e91c";
}

.icon-large-key:before {
  content: "\e91d";
}

.icon-large-parking:before {
  content: "\e91e";
}

.icon-large-route:before {
  content: "\e91f";
}

.icon-large-security:before {
  content: "\e920";
}

.icon-large-ticket-no:before {
  content: "\e921";
}

.icon-large-ticket:before {
  content: "\e922";
}

.icon-left:before {
  content: "\e923";
}

.icon-line-bike:before {
  content: "\e924";
}

.icon-line-car:before {
  content: "\e925";
}

.icon-line-park:before {
  content: "\e926";
}

.icon-line-van:before {
  content: "\e927";
}

.icon-mail:before {
  content: "\e928";
}

.icon-minus:before {
  content: "\e929";
}

.icon-money:before {
  content: "\e92a";
}

.icon-moto-type:before {
  content: "\e92b";
}

.icon-orari:before {
  content: "\e92c";
}

.icon-park-coperto:before {
  content: "\e92d";
}

.icon-park-scoperto:before {
  content: "\e92e";
}

.icon-phone:before {
  content: "\e92f";
}

.icon-plane:before {
  content: "\e930";
}

.icon-plus:before {
  content: "\e931";
}

.icon-right:before {
  content: "\e932";
}

.icon-search:before {
  content: "\e933";
}

.icon-security:before {
  content: "\e934";
}

.icon-star-empty:before {
  content: "\e935";
}

.icon-star-full:before {
  content: "\e936";
}

.icon-star-half:before {
  content: "\e937";
}

.icon-suv-clean:before {
  content: "\e938";
}

.icon-suv-fresh:before {
  content: "\e939";
}

.icon-tieni-chiavi:before {
  content: "\e93a";
}

.icon-train:before {
  content: "\e93b";
}

.icon-up:before {
  content: "\e93c";
}

.icon-user:before {
  content: "\e93d";
}

.icon-van-type:before {
  content: "\e93e";
}

.icon-venice:before {
  content: "\e93f";
}

.icon-video:before {
  content: "\e940";
}

.icon-walk:before {
  content: "\e941";
}