@keyframes showContainer {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.search-container {
  animation: showContainer 0.4s ease-in-out;
}